"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OBJECT_TYPES = exports.ObjectType = void 0;
var ObjectType;
(function (ObjectType) {
    ObjectType[ObjectType["Bunny"] = 0] = "Bunny";
    ObjectType[ObjectType["Bat"] = 1] = "Bat";
    ObjectType[ObjectType["Spawn"] = 2] = "Spawn";
})(ObjectType || (exports.ObjectType = ObjectType = {}));
exports.OBJECT_TYPES = [
    'bunny',
    'bat',
    'spawn'
];
