"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerMessages = void 0;
var ServerMessages;
(function (ServerMessages) {
    ServerMessages[ServerMessages["SendMessage"] = 0] = "SendMessage";
    ServerMessages[ServerMessages["BroadcastMessage"] = 1] = "BroadcastMessage";
    ServerMessages[ServerMessages["BunnyIdle"] = 2] = "BunnyIdle";
    ServerMessages[ServerMessages["BunnyJumped"] = 3] = "BunnyJumped";
    ServerMessages[ServerMessages["BunnyDied"] = 4] = "BunnyDied";
    ServerMessages[ServerMessages["BunnyGhost"] = 5] = "BunnyGhost";
    ServerMessages[ServerMessages["BunnyReset"] = 6] = "BunnyReset";
})(ServerMessages || (exports.ServerMessages = ServerMessages = {}));
