"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerObjectAnimationLookup = exports.ServerObjectAnimations = exports.ServerObjectAnimation = void 0;
var ServerObjectAnimation;
(function (ServerObjectAnimation) {
    ServerObjectAnimation[ServerObjectAnimation["Idle"] = 0] = "Idle";
    ServerObjectAnimation[ServerObjectAnimation["Jump"] = 1] = "Jump";
})(ServerObjectAnimation || (exports.ServerObjectAnimation = ServerObjectAnimation = {}));
exports.ServerObjectAnimations = [
    'idle',
    'jump'
];
exports.ServerObjectAnimationLookup = new Map();
exports.ServerObjectAnimationLookup.set('idle', 0);
exports.ServerObjectAnimationLookup.set('jump', 1);
